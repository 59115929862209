import _ from 'lodash';
import {
  ConditionMonitorInputV1,
  ConditionMonitorNotificationConfigurationInputV1,
  ConditionMonitorOutputV1,
  EmailNotificationRecipientOutputV1,
  NotificationConfigurationOutputV1,
  sqConditionMonitorsApi,
  sqItemsApi,
  sqNotificationConfigurationsApi,
} from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { sqWorkbenchStore, sqWorkbookStore, sqWorkstepsStore } from '@/core/core.stores';
import { CapsuleGroupingEnum } from '@/sdk/model/NotificationConfigurationOutputV1';
import { TItemProperties } from '@/tools/itemProperties/hooks/useProperties';
import { TREND_COLUMNS } from '@/trendData/trendData.constants';
import { getViewWorksheetLink } from '@/main/routing.utilities';
import { doTrack } from '@/track/track.service';
import { IdentityOption } from '@/core/SelectIdentity.molecule';
import i18next from 'i18next';
import { updateConditionNotificationIds } from '@/notifications/notifications.actions';
import { TrackInformation } from '@/track/track.types';

export interface ConditionMonitorNotification {
  conditionMonitor: ConditionMonitorOutputV1;
  notificationConfiguration: NotificationConfigurationOutputV1;
  conditionNames: Record<string, string>;
}

const DEFAULT_CAPSULE_PROPERTIES = [
  _.find(TREND_COLUMNS, { key: 'startTime' })!,
  _.find(TREND_COLUMNS, { key: 'endTime' })!,
  _.find(TREND_COLUMNS, { key: 'duration' })!,
];

const DEFAULT_CONDITION_PROPERTIES = [
  _.find(TREND_COLUMNS, { key: 'asset' })!,
  _.find(TREND_COLUMNS, { key: 'fullpath' })!,
];

export const DEFAULT_PROPERTIES = DEFAULT_CAPSULE_PROPERTIES.concat(DEFAULT_CONDITION_PROPERTIES);

export function getDefaultContextUrl(): string {
  return getViewWorksheetLink(
    sqWorkbenchStore.stateParams.workbookId,
    sqWorkbenchStore.stateParams.worksheetId,
    undefined,
    sqWorkstepsStore.current.id,
    false,
  );
}

function getWorksheetAndWorkbookName(): string {
  return `${sqWorkbookStore.name} - ${sqWorkbookStore.getWorksheetName(sqWorkbenchStore.stateParams.worksheetId)}`;
}

export const newConditionMonitorNotification = (
  condition: TItemProperties,
  includeAssetProperties: boolean,
): ConditionMonitorNotification => {
  let defaultProperties = DEFAULT_CAPSULE_PROPERTIES;
  if (includeAssetProperties) {
    defaultProperties = defaultProperties.concat(DEFAULT_CONDITION_PROPERTIES);
  }
  return {
    conditionMonitor: {
      id: '',
      conditionIds: [condition.id],
      enabled: true,
      name: `${condition.name}`,
      createdAt: '',
      creatorId: '',
      updatedAt: '',
      queryRangeLookAhead: '0',
      type: SeeqNames.Types.ConditionMonitor,
    },
    notificationConfiguration: {
      capsuleProperties: _.map(defaultProperties, (p) => p.propertyName as string),
      capsuleGrouping: CapsuleGroupingEnum.ALL,
      timezone: sqWorkbenchStore.timezone.name,
      contextualText: `<p>${i18next.t('NOTIFICATIONS.MODAL.CUSTOMIZE_EMAIL_SEE_MORE_LINK', {
        link: `<a href="${getDefaultContextUrl()}">${getWorksheetAndWorkbookName()}</a>`,
      })}</p>`,
      toEmailRecipients: [
        {
          identityId: sqWorkbenchStore.currentUser.id,
          name: sqWorkbenchStore.currentUser.name,
        },
      ],
      ccEmailRecipients: [],
      bccEmailRecipients: [],
    },
    conditionNames: { [condition.id]: condition.name },
  };
};

export async function fetchConditionMonitor(conditionMonitorId: string): Promise<ConditionMonitorNotification> {
  const { data: conditionMonitor } = await sqConditionMonitorsApi.getConditionMonitor({ id: conditionMonitorId });
  const { data: notificationConfiguration } = await sqNotificationConfigurationsApi.getNotificationConfiguration({
    id: conditionMonitorId,
  });
  const conditionNames = await Promise.all(
    _.map(conditionMonitor.conditionIds, (id) => {
      if (conditionMonitor.conditionIds.length > 10) {
        return '';
      } else {
        try {
          return sqItemsApi.getItemAndAllProperties({ id }).then(({ data: { name } }) => name);
        } catch (e) {
          return i18next.t('NOTIFICATIONS.MODAL.CONDITION_NOT_FOUND', { id });
        }
      }
    }),
  );

  return {
    conditionMonitor,
    notificationConfiguration,
    conditionNames: _.zipObject(conditionMonitor.conditionIds, conditionNames),
  };
}

export async function saveConditionMonitorNotificationConfiguration(
  conditionMonitorInput: ConditionMonitorInputV1,
  notificationConfigurationInput: ConditionMonitorNotificationConfigurationInputV1,
  id?: string,
): Promise<NotificationConfigurationOutputV1> {
  const { data: savedConditionMonitor } = id
    ? await sqConditionMonitorsApi.updateConditionMonitor(conditionMonitorInput, { id })
    : await sqConditionMonitorsApi.createConditionMonitor(conditionMonitorInput);

  let config: NotificationConfigurationOutputV1;
  try {
    const { data } = await sqNotificationConfigurationsApi.setNotificationConfigurationForConditionMonitor(
      notificationConfigurationInput,
      { id: savedConditionMonitor.id },
    );
    config = data;
  } catch (e) {
    // If we were creating a new condition monitor, we need to guarantee that the caller can provide the newly
    // created condition monitor id for future calls to this function.
    if (!id) {
      sqConditionMonitorsApi.archiveConditionMonitor({ id: savedConditionMonitor.id });
    }

    throw e;
  }

  updateConditionNotificationIds(conditionMonitorInput.conditionIds);

  return config;
}

export const readOnlyRecipientsList = (recipients: EmailNotificationRecipientOutputV1[]): string[] =>
  _.map(recipients, (recipient) => recipient.name ?? recipient.emailAddress ?? '');

export const recipientsToIdentities = (recipients: EmailNotificationRecipientOutputV1[]): IdentityOption[] =>
  _.map(recipients, (recipient) => ({
    id: recipient.identityId!,
    name: recipient.name ?? recipient.emailAddress ?? '',
    email: recipient.emailAddress,
  }));

export const identitiesToRecipients = (identities: IdentityOption[]): EmailNotificationRecipientOutputV1[] =>
  _.map(identities, (identity) =>
    identity.customOption
      ? { name: identity.label!, emailAddress: identity.label! }
      : { identityId: identity.id, name: identity.name },
  );

export const recipientsToInput = (recipients: EmailNotificationRecipientOutputV1[]): string[] =>
  _.map(recipients, (recipient) => recipient.identityId ?? recipient.emailAddress ?? recipient.name ?? '');

export function hasRecipients(notificationConfiguration?: NotificationConfigurationOutputV1): boolean {
  return !_.isEmpty(notificationConfiguration?.toEmailRecipients);
}

export function saveNotificationFieldTracking(
  conditionMonitor: ConditionMonitorOutputV1,
  notificationConfiguration: NotificationConfigurationOutputV1,
) {
  const { capsuleProperties, toEmailRecipients, ccEmailRecipients, bccEmailRecipients, capsuleGrouping } =
    notificationConfiguration;

  doTrack('Notification', 'Create', {
    enabled: conditionMonitor.enabled,
    capsulePropertyNames: capsuleProperties.toString(),
    capsulePropertiesCount: capsuleProperties.length,
    toEmails: toEmailRecipients.length,
    ccEmails: ccEmailRecipients.length,
    bccEmails: bccEmailRecipients.length,
    lookAheadSeconds: conditionMonitor.queryRangeLookAhead,
    capsuleGrouping,
  });
}

export function updateNotificationFieldTracking(
  conditionMonitor: ConditionMonitorOutputV1,
  conditionMonitorOriginal: ConditionMonitorOutputV1,
  notificationConfiguration: NotificationConfigurationOutputV1,
  notificationConfigurationOriginal: NotificationConfigurationOutputV1,
) {
  const trackInformation: TrackInformation = {};

  if (conditionMonitor.enabled !== conditionMonitorOriginal.enabled) {
    trackInformation.enabled = conditionMonitor.enabled;
  }

  if (conditionMonitor.name !== conditionMonitorOriginal.name) {
    trackInformation.name = 'Name';
  }

  if (notificationConfiguration.capsuleProperties !== notificationConfigurationOriginal.capsuleProperties) {
    trackInformation.capsulePropertyNames = notificationConfiguration.capsuleProperties.toString();
    trackInformation.capsulePropertiesCount = notificationConfiguration.capsuleProperties.length;
  }

  if (notificationConfiguration.contextualText !== notificationConfigurationOriginal.contextualText) {
    trackInformation.contextualText = 'Email Text';
  }

  if (!_.isEqual(notificationConfiguration.toEmailRecipients, notificationConfigurationOriginal.toEmailRecipients)) {
    trackInformation.toEmails = notificationConfiguration.toEmailRecipients.length;
  }

  if (
    notificationConfiguration?.ccEmailRecipients.length !== notificationConfigurationOriginal?.ccEmailRecipients.length
  ) {
    trackInformation.ccEmails = notificationConfiguration.ccEmailRecipients.length;
  }

  if (!_.isEqual(notificationConfiguration.bccEmailRecipients, notificationConfigurationOriginal.bccEmailRecipients)) {
    trackInformation.bccEmails = notificationConfiguration.bccEmailRecipients.length;
  }

  if (conditionMonitor.queryRangeLookAhead !== conditionMonitorOriginal.queryRangeLookAhead) {
    trackInformation.lookAheadSeconds = conditionMonitor.queryRangeLookAhead;
  }

  if (notificationConfiguration.timezone !== notificationConfigurationOriginal.timezone) {
    trackInformation.timezone = 'Timezone';
  }

  if (notificationConfiguration.capsuleGrouping !== notificationConfigurationOriginal.capsuleGrouping) {
    trackInformation.capsuleGrouping = notificationConfigurationOriginal.capsuleGrouping;
  }

  doTrack('Notification', 'Update', trackInformation);
}

export interface EmailValidity {
  to: boolean;
  cc: boolean;
  bcc: boolean;
}

function isValidEmail(email: string): boolean {
  return !!email
    .toLowerCase()
    // Simple match: check for [something]@[something].[something]
    // From
    // https://www.w3resource.com/javascript/form/email-validation.php#:~:text=To%20get%20a%20valid%20email,%5D%2B)*%24%2F
    .match(/^\S+@\S+\.\S+$/);
}

function isValidRecipient(recipient: EmailNotificationRecipientOutputV1): boolean {
  return !_.isEmpty(recipient.identityId ?? recipient.name) || isValidEmail(recipient.emailAddress ?? '');
}

export function validateEmails(
  notificationConfiguration?: Pick<
    NotificationConfigurationOutputV1,
    'toEmailRecipients' | 'ccEmailRecipients' | 'bccEmailRecipients'
  >,
): EmailValidity | undefined {
  if (notificationConfiguration === undefined) {
    return undefined;
  }
  const validator = (recipients: Array<EmailNotificationRecipientOutputV1>) =>
    _.every(recipients, (recipient) => isValidRecipient(recipient));

  return {
    to: validator(notificationConfiguration.toEmailRecipients),
    cc: validator(notificationConfiguration.ccEmailRecipients),
    bcc: validator(notificationConfiguration.bccEmailRecipients),
  };
}

export function allEmailsValid(emailValidity?: EmailValidity): boolean {
  return !!emailValidity && emailValidity.bcc && emailValidity.cc && emailValidity.to;
}
